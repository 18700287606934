import axios from "axios";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://127.0.0.1:8000/api/v1/"
      : "https://api.bokdeok.com/api/v1",
  withCredentials: true,
});

export const getRealEstateAgent = async (
  searchText: string,
  pageNum: number,
) => {
  const search_text = encodeURIComponent(searchText);
  return instance
    .get(`realEstateAgent?search=${search_text}&page=${pageNum}`)
    .then((response) => response.data);
};

export const getRealEstateIssue = (crqfcNo: string) => {
  const crqfc_no = encodeURIComponent(crqfcNo);
  return instance
    .get(`realEstateIssue?crqfc_no=${crqfc_no}`)
    .then((response) => response.data);
};

export const getRealEstateMainIssue = () => {
  return instance.get(`realEstateIssue/home`).then((response) => response.data);
};
