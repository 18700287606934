import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  Box,
} from "@chakra-ui/react";
import { IRealEstateMainIssueInfo } from "../../types/real_estate_types";

export const RealEstateIssueCard = ({
  ldCodeNm,
  bsnmCmpnm,
  brkrNm,
  sttusSeCodeNm,
  lastUpdtDt,
}: IRealEstateMainIssueInfo) => {
  return (
    <Box>
      <Card>
        <CardHeader>
          <Heading size={"md"} mb={1}>
            {brkrNm} ({sttusSeCodeNm})
            <Text fontSize={"xs"}>({lastUpdtDt.toString()} 확인)</Text>
          </Heading>
        </CardHeader>
        <CardBody>
          <Box isTruncated maxW={"270px"}>
            <Text size={"sm"} mb={1}>
              지역: {ldCodeNm}
            </Text>
          </Box>
          <Heading size={"sm"}>
            <Box isTruncated maxW={"270px"}>
              {bsnmCmpnm}
            </Box>
          </Heading>
        </CardBody>
      </Card>
    </Box>
  );
};
