import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Heading,
  Text,
  HStack,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { IRealEstateInfo } from "../../types/real_estate_types";
import { RealEstateDetail } from "./RealEstateDetail";

export const RealEstateCard = ({
  ldCodeNm,
  bsnmCmpnm,
  brkrNm,
  brkrAsortCodeNm,
  crqfcNo,
  sttusSeCode,
  sttusSeCodeNm,
  lastUpdtDt,
}: IRealEstateInfo) => {
  const {
    isOpen: isDetailOpen,
    onClose: onDetailClose,
    onOpen: onDetailOpen,
  } = useDisclosure();

  return (
    <Box>
      {sttusSeCode === "8" ? (
        <Card>
          <CardHeader>
            <Heading size={"lg"} mb={1} color={"red"}>
              <Box isTruncated maxW={"270px"}>
                {brkrNm}
                {brkrAsortCodeNm ? " (" + brkrAsortCodeNm + ")" : null}
              </Box>
            </Heading>
            <Heading size={"sm"}>
              <HStack>
                <Text>상태 :</Text>
                <Text color={"red"}>{sttusSeCodeNm}</Text>
                <Text ml={"-1.5"} fontSize={"xs"}>
                  ({lastUpdtDt.toString()} 확인)
                </Text>
              </HStack>
            </Heading>
          </CardHeader>
          <CardBody>
            <Box isTruncated maxW={"270px"}>
              <Text mb={1}>지역: {ldCodeNm}</Text>
            </Box>
            <Heading color={"red"} size={"md"}>
              <Box isTruncated maxW={"270px"}>
                {bsnmCmpnm}
              </Box>
            </Heading>
          </CardBody>
          <CardFooter>
            <Button onClick={onDetailOpen}>과거 이력 보기</Button>
          </CardFooter>
          <RealEstateDetail
            isOpen={isDetailOpen}
            onClose={onDetailClose}
            brkrNm={brkrNm}
            bsnmCmpnm={bsnmCmpnm}
            crqfcNo={crqfcNo}
          />
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <Heading size={"lg"} mb={1}>
              <Box isTruncated maxW={"270px"}>
                {brkrNm}
                {brkrAsortCodeNm ? " (" + brkrAsortCodeNm + ")" : null}
              </Box>
            </Heading>
            <Heading size={"sm"}>
              <HStack>
                <Text>상태 :</Text>
                <Text>{sttusSeCodeNm}</Text>
                <Text ml={"-1.5"} fontSize={"xs"}>
                  ({lastUpdtDt.toString()} 확인)
                </Text>
              </HStack>
            </Heading>
          </CardHeader>
          <CardBody>
            <Box isTruncated maxW={"270px"}>
              <Text mb={1}>지역: {ldCodeNm}</Text>
            </Box>
            <Heading size={"md"}>
              <Box isTruncated maxW={"270px"}>
                {bsnmCmpnm}
              </Box>
            </Heading>
          </CardBody>
          <CardFooter>
            <Button onClick={onDetailOpen}>과거 이력 보기</Button>
          </CardFooter>
          <RealEstateDetail
            isOpen={isDetailOpen}
            onClose={onDetailClose}
            brkrNm={brkrNm}
            bsnmCmpnm={bsnmCmpnm}
            crqfcNo={crqfcNo}
          />
        </Card>
      )}
    </Box>
  );
};
