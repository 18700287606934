import { Button, Heading, Link, Text, VStack } from "@chakra-ui/react";

export const NotFound = () => (
  <VStack justifyContent={"center"} minH='100vh'>
    <Heading>검색하시려는 페이지를 찾을 수 없습니다.</Heading>
    <Text>주소를 다시 확인해주세요.</Text>
    <Link href='/'>
      <Button colorScheme={"red"} variant={"link"}>
        홈으로 &rarr;
      </Button>
    </Link>
  </VStack>
);
