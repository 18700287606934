import React from "react";
import { useQuery } from "@tanstack/react-query";
import { IRealEstateMainIssueInfo } from "../types/real_estate_types";
import {
  useColorModeValue,
  Text,
  VStack,
  Container,
  InputGroup,
  Input,
  InputLeftElement,
  Box,
  useToast,
  Button,
  Image,
  Grid,
  HStack,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { FaSearch, FaRegBuilding } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import mainTitleSun from "../public_assets/main_title_sun.png";
import mainTitleNight from "../public_assets/main_title_night.png";
import { getRealEstateMainIssue } from "../apis/realEstateApi";
import { RealEstateIssueCard } from "../components/realEstate/RealEstateIssueCard";

interface ISearchForm {
  realEstateInfo: string;
}

export const Home = () => {
  const { data: realEstateMainIssueInfoData } = useQuery<
    IRealEstateMainIssueInfo[]
  >(["realEstateMainIssueInfo"], getRealEstateMainIssue);
  const { register, handleSubmit, reset } = useForm<ISearchForm>();
  const navigate = useNavigate();
  const toast = useToast();
  const defaultColor = useColorModeValue("black", "gray.500");
  const MainTitleColor = useColorModeValue(mainTitleSun, mainTitleNight);
  const onSubmit = ({ realEstateInfo }: ISearchForm) => {
    if (realEstateInfo.length > 1 && realEstateInfo.length < 20) {
      navigate(`/searchRealEstateInfo?search=${realEstateInfo}`);
      reset();
    } else {
      toast({
        title: "검색어가 짧거나, 너무 깁니다.",
        status: "error",
        position: "bottom-right",
      });
    }
  };
  const enterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit(onSubmit);
    }
  };
  return (
    <Container maxW={"70%"}>
      <Helmet>
        <title>복덕</title>
        <meta name='description' content='부동산 중개인 영업정지 내역 조회' />
        <meta
          name='keywords'
          content='공인중개사 사기, 부동산 사기, 전세 사기, 중개인 사기, 깡통전세, 부동산 중개인'
        />
        <meta property='og:title' content='부동산 중개인 영업정지 내역 조회' />
        <meta
          property='og:description'
          content='공인중개사 사기, 부동산 사기, 전세 사기, 중개인 사기, 깡통전세, 부동산 중개인'
        />
      </Helmet>
      <VStack justifyContent={"center"} minH={"30vh"}>
        <VStack
          justifyContent={"center"}
          as={"form"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Image alt='중개인 내역 조회' src={MainTitleColor} w={"xl"} />
          <InputGroup w={"100%"}>
            <InputLeftElement
              children={
                <Box w={"20"} ml={"3"}>
                  <FaSearch />
                </Box>
              }
            />
            <Input
              {...register("realEstateInfo", {
                required: true,
              })}
              maxW={"100%"}
              borderColor={defaultColor}
              borderRadius={"full"}
              placeholder='중개인 명 또는 사무소 명'
              onKeyDown={enterKeyPress}
            />
            <Button type='submit' hidden />
          </InputGroup>
        </VStack>
      </VStack>
      <HStack justifyContent={"center"}>
        <FaRegBuilding />
        <Text>현재 업무정지 중개인 리스트</Text>
      </HStack>
      <Grid
        justifyContent={"center"}
        mt={3}
        gap={6}
        templateColumns={{
          sm: "80%",
          md: "repeat(2, 40%)",
          lg: "repeat(2, 40%)",
          xl: "repeat(3, 26%)",
        }}
      >
        {realEstateMainIssueInfoData?.map((realEstateIssueInfo, index) => (
          <RealEstateIssueCard
            key={index}
            ldCodeNm={realEstateIssueInfo.ldCodeNm}
            bsnmCmpnm={realEstateIssueInfo.bsnmCmpnm}
            brkrNm={realEstateIssueInfo.brkrNm}
            brkrAsortCodeNm={realEstateIssueInfo.brkrAsortCodeNm}
            sttusSeCodeNm={realEstateIssueInfo.sttusSeCodeNm}
            lastUpdtDt={realEstateIssueInfo.lastUpdtDt}
            crqfcNo={realEstateIssueInfo.crqfcNo}
          />
        ))}
      </Grid>
    </Container>
  );
};
