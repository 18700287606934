import { createBrowserRouter } from "react-router-dom";
import Root from "./components/Roots";
import { NotFound } from "./routes/NotFound";
import { Home } from "./routes/Home";
import { RealEstateInfo } from "./routes/realEstate/RealEstateInfo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/searchRealEstateInfo",
        element: <RealEstateInfo />,
      },
    ],
  },
]);

export default router;
