import {
  Box,
  IconButton,
  HStack,
  Container,
  useColorMode,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaMoon, FaSun } from "react-icons/fa";
import logoPathSun from "../public_assets/bd_logo_sun.png";
import logoPathNight from "../public_assets/bd_logo_night.png";

export const Header = () => {
  const { toggleColorMode } = useColorMode();
  const LogoColor = useColorModeValue(logoPathSun, logoPathNight);
  const IconDesign = useColorModeValue(FaMoon, FaSun);
  return (
    <Container maxW={"95%"} pt={"3%"} pl={"3%"} pr={"3%"}>
      <HStack justifyContent='space-between' alignItems='center'>
        <Box>
          <Link to={"/"} aria-label='Go Home'>
            <Image alt='main logo' src={LogoColor} />
          </Link>
        </Box>
        <Box>
          <IconButton
            onClick={toggleColorMode}
            variant={"ghost"}
            aria-label='Toggle dark mode'
            icon={<IconDesign size={"27"} />}
          />
        </Box>
      </HStack>
    </Container>
  );
};
