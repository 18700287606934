import {
  Container,
  Grid,
  Heading,
  VStack,
  useToast,
  Divider,
  Spinner,
  Text,
  Box,
  Link,
  Button,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { IRealEstateInfo } from "../../types/real_estate_types";
import { getRealEstateAgent } from "../../apis/realEstateApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { RealEstateCard } from "../../components/realEstate/RealEstateCard";
import InfiniteScroll from "react-infinite-scroller";

export const RealEstateInfo: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const searchText = searchParams.get("search");

  const [realEstateInfoData, setRealEstateInfoData] = useState<
    IRealEstateInfo[]
  >([]);
  const [hasMoreRealEstateInfo, setHasMoreRealEstateInfo] = useState(true);
  const [noDataFlag, setNoDataFlag] = useState(false);

  useEffect(() => {
    if (!searchText || searchText.length < 2 || searchText.length > 20) {
      toast({
        title: "검색어가 짧거나, 너무 깁니다.",
        status: "error",
        position: "bottom-right",
      });
      navigate("/");
    } else if (typeof searchText != "string") {
      toast({
        title: "올바른 검색어를 입력 해주세요.",
        status: "error",
        position: "bottom-right",
      });
      navigate("/");
    }

    (async () => {
      if (searchText) {
        const data = await getRealEstateAgent(searchText!, 1);
        if (data && data.length === 0) {
          setNoDataFlag(true);
        }
        setRealEstateInfoData(data);
      }
    })();
  }, [searchText, toast, navigate]);

  async function loadMoreRealEstate(pageNum: number) {
    try {
      if (searchText) {
        let newData: IRealEstateInfo[] = await getRealEstateAgent(
          searchText,
          pageNum,
        );

        if (newData && newData.length > 0) {
          setRealEstateInfoData((prevItems) => [...prevItems, ...newData]);
        } else {
          setHasMoreRealEstateInfo(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container pt={"10vh"} maxW={"90%"}>
      <Helmet>
        <title>중개인 내역 조회</title>
        <meta
          name='description'
          content='부동산 중개인 영업정지, 공인중개사 영업정지, 부동산 영업정지 내역 조회'
        />
        <meta
          name='keywords'
          content='부동산 중개인 사기, 부동산 사기, 전세 사기, 중개인 사기, 깡통전세, 부동산 영업정지, 부동산 중개인 영업정지'
        />
        <meta
          property='og:title'
          content='부동산 중개인, 부동산 중개인 영업정지, 사기 내역 조회'
        />
        <meta
          property='og:description'
          content='부동산 중개인 사기, 부동산 사기, 전세 사기, 중개인 사기, 깡통전세'
        />
      </Helmet>
      <VStack align={"left"}>
        <Heading size={"xl"}>중개인 검색 결과</Heading>
        <Divider w={"95%"} border={"1px"} mt={"3"} />
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMoreRealEstate}
          hasMore={hasMoreRealEstateInfo}
          loader={
            <Container
              key={0}
              display={"flex"}
              justifyContent={"center"}
              alignItems='center'
              mt={"10"}
            >
              <Spinner size='xl' />
            </Container>
          }
        >
          <Grid
            justifyContent={"center"}
            mt={10}
            gap={6}
            templateColumns={{
              sm: "80%",
              md: "repeat(2, 40%)",
              lg: "repeat(2, 40%)",
              xl: "repeat(3, 26%)",
            }}
          >
            {realEstateInfoData.map((realEstateInfo) => (
              <RealEstateCard
                key={`card_${realEstateInfo.id}`}
                id={realEstateInfo.id}
                brkrNm={realEstateInfo.brkrNm}
                brkrAsortCodeNm={realEstateInfo.brkrAsortCodeNm}
                ldCodeNm={realEstateInfo.ldCodeNm}
                bsnmCmpnm={realEstateInfo.bsnmCmpnm}
                sttusSeCode={realEstateInfo.sttusSeCode}
                sttusSeCodeNm={realEstateInfo.sttusSeCodeNm}
                lastUpdtDt={realEstateInfo.lastUpdtDt}
                crqfcNo={realEstateInfo.crqfcNo}
              />
            ))}
          </Grid>
          {noDataFlag ? (
            <Box ml={"10vh"}>
              <Heading size={"md"} color={"red"}>
                일치하는 데이터가 없습니다.
              </Heading>
              <Text mt={2}>
                조회하시려는 중개인 명 또는 중개업 사무소를 다시 확인해주세요.
              </Text>
              <Text mt={5}>
                이 데이터는 국가공간정보포털 제공 데이터를 기반으로 하고
                있습니다. <br /> 차후에는 검증된 실제 자료를 수집할 예정입니다.
              </Text>
              <Link href='/'>
                <Button variant={"link"}>다시 조회 하러 가기 &rarr;</Button>
              </Link>
            </Box>
          ) : null}
        </InfiniteScroll>
      </VStack>
    </Container>
  );
};
