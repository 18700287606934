import { useQuery } from "@tanstack/react-query";
import { IRealEstateIssueInfo } from "../../types/real_estate_types";
import { getRealEstateIssue } from "../../apis/realEstateApi";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

interface RealEstateInfoProps {
  isOpen: boolean;
  onClose: () => void;
  brkrNm: string;
  bsnmCmpnm: string;
  crqfcNo: string;
}

export const RealEstateDetail = ({
  isOpen,
  onClose,
  brkrNm,
  bsnmCmpnm,
  crqfcNo,
}: RealEstateInfoProps) => {
  const { data: realEstateIssueInfoList } = useQuery<IRealEstateIssueInfo[]>(
    ["realEstateIssueInfo", crqfcNo],
    () => getRealEstateIssue(crqfcNo),
    {
      enabled: isOpen,
    },
  );
  return (
    <Modal onClose={onClose} isOpen={isOpen} size={"2xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"center"} fontSize={"2xl"}>
          {bsnmCmpnm.length > 0 ? `${brkrNm}(${bsnmCmpnm})` : `${brkrNm}`}
        </ModalHeader>
        <ModalHeader ml={"2vh"}>영업정지 내역</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TableContainer>
            <Table variant={"striped"} size='md'>
              <Thead>
                <Tr>
                  <Th>영업정지 연도</Th>
                  <Th>지역</Th>
                  <Th>상호명</Th>
                </Tr>
              </Thead>
              <Tbody>
                {realEstateIssueInfoList?.length &&
                realEstateIssueInfoList?.length > 0 ? (
                  realEstateIssueInfoList?.map((realEstateIssueInfo) => (
                    <Tr>
                      <Td>
                        {realEstateIssueInfo.estbsBeginDe.substring(0, 4)}년
                      </Td>
                      <Td>{realEstateIssueInfo.ldCodeNm}</Td>
                      <Td>{realEstateIssueInfo.bsnmCmpnm}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td textAlign={"center"} colSpan={3}>
                      영업 정지 이력 없음
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
